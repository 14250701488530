.header {
  background: #000000;
  color: white;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  height: 55px;
  width: 100vw;
  position: fixed;
  left: 0;
  top: 0;
  z-index: 10;
  overflow-x: visible;
  overflow-y: visible;
}

.drop .btn {
  background-color: #00b7ff;
  color: #000;
}

.drop .btn:hover {
  background-color: #00b7ff;
  color: #000;
}

.header .active {
  background-color: #202020;
  border-bottom: 1px solid #00b7ff;
}

.header a {
  font-weight: bold;
  color: #6d6d6d;
  text-decoration: none;
}

.header a:hover {
  background-color: #202020;
  color: #fff;
}

@media only screen and (max-width: 600px) {
  .header {
    position: fixed;
    top: 0;
    left: 0;
    height: 60px;
    display: flex;
    flex-direction: row;
    width: -webkit-fill-available;
    background-color: #050505;
    border-bottom: none;
  }

  .header a {
    font-weight: bold;
    color: #6d6d6d;
    text-decoration: none;
  }

  .header a:hover {
    background-color: #050505;
    color: none;
  }

  .header .active {
    background-color: #050505;
    border-left: none;
  }
}

h2,
h1,
h3,
h4 {
  color: #fff;
  font-weight: lighter;
}

.page_container {
  min-height: calc(100vh - 55px);
  width: calc(100vw);
  background-color: #000;
  color: #fff;
  margin-top: 55px;
  padding: 20px;
}

.card_container {
  display: grid;
  grid-template-columns: 32% 32% 32%;
}

@media only screen and (max-width: 600px) {
  .page_container {
    min-height: calc(100vh - 110px);
    min-width: 100vw;
    margin: 0px;
    color: #fff;
    background-color: #000;
    margin-top: 110px;
  }

  .card_container {
    display: block;
  }
}

/* home start */

.oneLink {
  width: -webkit-fill-available;
  flex-direction: row;
  align-items: center;
  padding: 20px;
  border-radius: 20px;
  background-color: #cccccc10;
  border: 1px solid #505050;
}

.my-masonry-grid {
  display: -webkit-box;
  /* Not needed if autoprefixing */
  display: -ms-flexbox;
  /* Not needed if autoprefixing */
  display: flex;
  /* gutter size offset */
  width: auto;
  margin-top: 20px;
  justify-content: space-between;
}

.my-masonry-grid_column {
  /* gutter size */
  background-clip: padding-box;
}

/* Style your items */
.my-masonry-grid_column>div {
  /* change div to reference your elements you put in <Masonry> */
  margin: 5px;
  margin-bottom: 10px;
}

.addLinkModal .modal-content {
  background-color: #202020;
}

.searchbar_container {
  width: -webkit-fill-available;
  display: flex;
  justify-content: center;
  margin-bottom: 10px;
}

.search_input {
  background: none;
  border: none;
  outline: none;
  color: #00b7ff;
  width: 300px;
}

.glass {
  background: rgba(0, 0, 0, 0.25);

  box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.5);
  backdrop-filter: blur(10px);
  -webkit-backdrop-filter: blur(10px);
  border-radius: 10px;
}